import React from "react";
import { BackWrapper } from "../components/backwrapper";
import { CardButtonStyle } from "../components/cardroute";
export const InstructionButton = (props: any) => {
    return (
        <React.Fragment>
            <BackWrapper>
                <div className='h-[100%] w-[100%] sm:w-[500px] font-normal flex flex-col items-center justify-center'>
                    <p className='m-2 font-bold text-center'>
                        {props.msg1}
                    </p>
                    <p className='m-2 font-bold text-center'>
                        {props.msg2}
                    </p>
                    <button className={CardButtonStyle}>{props.buttonTitle}</button>
                </div>
            </BackWrapper>
        </React.Fragment>
    );
}