import React from "react";
import { BackWrapper } from "../components/backwrapper";
import { CardButtonStyle } from "../components/cardroute";
export const Certifications = (props: any) => {
    return (
        <BackWrapper>
            <div className='overflow-scroll w-full h-full flex justify-center'>
                <div className="h-fit w-[500px] flex flex-col  items-center space-y-6 mb-[20px]">
                    <div className='text-center'>
                        <h1 className="text-[24px]">
                            Teed & Brown 
                        </h1>
                        <h1 className="text-[20px]">Licensed Connecticut Supervisors</h1>
                    </div>
                    <div>
                        <p>Christopher Brown = S-3665</p>
                        <p>Nathaniel Leiby = PMCS.0065467</p>
                    </div>
                    <div className='font-normal space-y-5'>
                        <div>
                            <p>If you have ANY questions at all about a pesticide or product, call or text Christopher Brown by clicking below. Do NOT proceed with a pesticide application if you are unsure about anything. Do NOT proceed with a pesticide application without proper written instructions.</p>
                        </div>
                        <div className="w-full flex flex-row justify-center space-x-10">
                            <a href={`tel:12039431823`} className={CardButtonStyle}> Text Christopher</a>
                            <a href={`sms:12039431823`} className={CardButtonStyle}> Phone Christopher</a>
                        </div>
                    </div>
                    <div className="">
                        <p>Christopher Brown = (203) 943-1823 - M</p>
                    </div>
                    <div className='font-normal space-y-5'>
                        <div>
                            <p>If you are unable to contact Christopher Brown, contact Nathaniel Leiby.</p>
                        </div>
                        <div className="w-full flex flex-row justify-center space-x-10">
                            <a href={`sms:12035832389`} className={CardButtonStyle}> Text Nathaniel</a>
                            <a href={`tel:12035832389`} className={CardButtonStyle}> Phone Nathaniel</a>
                        </div>
                    </div>
                </div>
            </div>
        </BackWrapper>
    )
}