
import { PERFORMANCE_APP, getLoginToken } from '../App';
import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, Outlet, Link, useParams } from "react-router-dom";

import { ProfileContext } from '../state/store';
import { CardLink } from '../components/cardlink';
import { CardRoute } from '../components/cardroute';
import { LayoutMain } from '../components/layoutmain';
import Avatar from '../components/avatar';
import AvatarLarge from '../components/avatarLarge';
import StatCard from '../components/statCard';
import StatCardSmall from '../components/statCardSmall';
import {
    Bars3Icon,
    XMarkIcon,
    UserGroupIcon,
    IdentificationIcon,
    BackspaceIcon,
    FireIcon,
    BoltIcon,
    ChartBarIcon,
    ShieldCheckIcon,
    ExclamationTriangleIcon,
    TrophyIcon


  } from '@heroicons/react/24/solid';
import Bookmarks from './bookmarks';
import { GetScoreCardActivity, GetScoreCardActivityForAnEmployee } from '../utils/api';
import Loading from '../components/loading';


interface CompType {
    type: string;
    title: string;
    link: string;
    key?: number;
}

const Components: Record<string, React.ComponentType<any>> = {
    link: CardLink,
    route: CardRoute
};


const Row = (props:any) => {
    return <div className='w-full'>
        {props.el}
    </div>
}

const RowLink = (props:any) => {

    return <Link style={{ textDecoration: 'none' }} to={!props.disabled && props.link} className='w-full'>
        {props.el}
    </Link>
}

const CurrentProductivityColor = (value: number) => {
    if (value >= 100) {
        return "text-green-400";
    } else if (value === 0) {
        return "text-gray-400";
    } else if (value >= 90 && value < 100) {
        return "text-yellow-400";
    } else {
        return "text-red-400";
    }
};

export const CardButtonStyle = `
    bg-green-800
    text-white
    p-2
    mx-5
    rounded
    text-center
    shadow-md
    cursor-pointer
    text-sm
    
    flex
    justify-center
    items-center
    font-bold
    
`;

export default function Profile() {
    const param = useParams();
    console.log(param);
    const token = getLoginToken();
    const context:any = React.useContext(ProfileContext);
    const [data, setData]: any = React.useState({});
    const [loading, setLoading]  = React.useState(true);
    const [profile, setProfile] = React.useState(context.profile);
    //const profile:any = context.profile;

    const getInitialData = async () => {
        const resp = await GetScoreCardActivity();
        if(resp){
            setData(resp);
            setTimeout(() => setLoading(false), 1000);
        } else {
            window.location.reload();
        }
    }

    const getEmployeeData = async () => {
        const resp = await GetScoreCardActivityForAnEmployee(param.id);
        
        if(resp){
            setData(resp);
            setProfile(resp.data.profile);
            setTimeout(() => setLoading(false), 1000);
        } else {
            window.history.back();
        }
    }

    React.useEffect(() => {
        if(param.id){
            getEmployeeData();
        } else {
            getInitialData();
        }
    }, []);

    if(loading){
        return <Loading />
    }

    return (
        <React.Fragment>
            <div className='h-[100%] w-[100%] flex flex-col items-center overflow-y-scroll sm:px-[10%] md:px-[30%]'>
                <Row el={
                        <AvatarLarge first={profile?.first} last={profile.last} avatar={profile.avatar}/>
                }/>
                {!param.id && <Row el={
                    <Link style={{ textDecoration: 'none' }} to={'/menu'}>
                        <div className={CardButtonStyle}>Go to Menu & Resources</div>
                    </Link>
                }/>}
                {param.id && <Row el={
                    <Link style={{ textDecoration: 'none' }} to={`/managers/points/${param.id}`}>
                        <div className={CardButtonStyle}>Award / Deduct Points</div>
                    </Link>
                }/>}
                <RowLink disabled={false} link={param.id ? `/employees-list/${param.id}/points` : '/profile/points'} el={<StatCard icon={<TrophyIcon className={'text-brandGreen'} />} title={"T&B POINTS Earned"} value={data?.metrics?.tnbpoints} unit={''}/>}/>
                <Row el={<StatCard icon={<BoltIcon className={CurrentProductivityColor(data?.metrics?.currentProductivityScore || 0)} />} title={"Current Productivity Score"} value={`${data?.metrics?.currentProductivityScore >= 0 ? data?.metrics?.currentProductivityScore : '-'}`} unit={data?.metrics?.currentProductivityScore >= 0 && '%'}/>}/>
                <Row el={<StatCard icon={<ExclamationTriangleIcon className={data?.metrics?.driverSafetyInstances?.length > 0 ? 'text-red-500':CurrentProductivityColor(0)} />}title={'Current Driving Safety Alerts'} value={`${data?.metrics?.driverSafetyInstances?.length > 0 ? data?.metrics?.driverSafetyInstances?.length : '-'}`}/>}/>
                <Row el={<StatCard icon={<FireIcon className={`${data?.metrics?.currentSteak > 0 ?'text-yellow-400' : 'text-gray-400'}`} />}title={'Current 100% Productivity Streak'} value={data?.metrics?.currentStreak >= 0 ? data?.metrics?.currentStreak : '-'}/>}/>
                <Row el={<StatCard icon={<FireIcon className={`${data?.metrics?.longestStreak > 0 ?'text-yellow-400' : 'text-gray-400'}`} />}title={'Longest 100% Productivity Streak'} value={data?.metrics?.longestStreak >= 0 ? data?.metrics?.longestStreak : '-'}/>}/>
                {/*<Row el={<StatCard icon={<ChartBarIcon className={CurrentProductivityColor(data?.metrics?.overallscore || 0)} />}title={'Company Standing'} value={'-'}/>}/>*/}
                {true && <React.Fragment><div className='w-full p-2 flex flex-row justify-between items-center'>
                    <h2 className='font-bold'>Score Card</h2>
                    <span className='text-gray-400 text-xs'>(Click the arrow see details)</span>
                </div>
                <RowLink disabled={false} link={param.id ? `/employees-list/${param.id}/activity` : '/profile/activity'} el={<StatCardSmall  icon={<ShieldCheckIcon className={`text-yellow-300`} width={20} height={20} />} title={'Productivity'} desc='Click to see details' value={`${data?.metrics?.averages.overall || 0}%`}/>}/>
                <RowLink disabled={false} link={param.id ? `/employees-list/${param.id}/learning` : '/profile/learning'} el={<StatCardSmall routeDisabled={false} icon={<ShieldCheckIcon className={`text-blue-300`} width={20} height={20}/>} title={'Learning'} desc='Click to see details' value={`${data?.metrics?.education?.learning?.score || 0}%`}/>}/>
                <RowLink disabled={true} link={param.id ? `/employees-list/${param.id}/safety` : '/profile/safety'} el={<StatCardSmall routeDisabled={true} icon={<ShieldCheckIcon className={`text-orange-300`} width={20} height={20}/>} title={'Safety'} desc='Click to see details' value={'-'}/>}/>
                <RowLink disabled={true} link={param.id ? `/employees-list/${param.id}/standing` : '/profile/standing'} el={<StatCardSmall routeDisabled={true} icon={<ShieldCheckIcon className={`text-green-300`} width={20} height={20}/>} title={'Company Standing'} desc='Click to see details' value={'-'}/>}/></React.Fragment>}
            </div>
        </React.Fragment>
    );
}

//desc='Click to see Activity'