import React, {useEffect, useState} from 'react';
import { Routes, Route, Outlet, Link, useParams } from "react-router-dom";
import { GetScoreCardActivity, GetScoreCardActivityForAnEmployee } from '../../utils/api';
import { ProfileContext } from '../../state/store';
import { ChevronRightIcon, CheckBadgeIcon } from '@heroicons/react/24/solid';
import Loading from '../../components/loading';

const ScoreCardTeamwork = (props: any) => {
    const param = useParams();
    const context:any = React.useContext(ProfileContext);
    const [profile, setProfile] = React.useState(context.profile);
    const [data, setData]: any = React.useState({});
    const [loading, setLoading]  = React.useState(true);

    const getInitialData = async () => {
        const resp = await GetScoreCardActivity();
        if(resp){
            setData(resp);
            setTimeout(() => setLoading(false), 1000);
        }
    }

    const getEmployeeData = async () => {
        const resp = await GetScoreCardActivityForAnEmployee(param.id);
        setProfile(resp.data.profile);
        if(resp){
            setData(resp);
            setTimeout(() => setLoading(false), 1000);
        } else {
            window.history.back();
        }
    }

    React.useEffect(() => {
        if(param.id){
            getEmployeeData();
        } else {
            getInitialData();
        }
    }, []);

    if(loading){
        return <Loading />
    }

    return (
        <div className="w-full h-full overflow-y-scroll p-4 m:px-[10%] md:px-[30%]">
            <div className='text-center font-bold text-brandGreen'>
                Learning Score
            </div>
            <div className='text-lg font-bold text-center my-6'>
                    {data?.metrics?.education?.learning?.score}% Completion Rate
            </div>
            <div className='w-full my-6 bg-gray-50 rounded-[12px] p-4 shadow-md'>

                <div className='text-black font-bold my-4'>
                    Assignments
                </div>
                <div className='flex flex-row py-1'>
                    <div className='w-full'></div>
                    <div className='w-full text-center text-brandGreen font-bold'>Points</div>
                </div>
                
                <div className='w-full flex flex-row'>
                    <div className='w-full'></div>
                    <div className='w-full flex flex-row text-center text-brandGreen font-bold'>
                        <div className='w-full'>Earned</div>
                        <div className='w-full'>Max</div>
                    </div>
                </div>
                <div className='w-full flex flex-row my-2'>
                    <div className='w-full font-bold'>{data?.metrics?.education?.learning?.completed} Completed</div>
                    <div className='w-full flex flex-row text-center'>
                        <div className='w-full'>{data?.metrics?.education?.learning?.completedPoints}</div>
                        <div className='w-[1%] mx-2'>/</div>
                        <div className='w-full'>{data?.metrics?.education?.learning?.completed * 100}</div>
                    </div>
                </div>
                <div className='w-full flex flex-row my-2'>
                    <div className='w-full font-bold'>{data?.metrics?.education?.learning?.incomplete} Incomplete</div>
                    <div className='w-full flex flex-row text-center'>
                        <div className='w-full'>0</div>
                        <div className='w-[1%] mx-2'>/</div>
                        <div className='w-full'>{data?.metrics?.education?.learning?.incomplete * 100}</div>
                    </div>
                </div>
                <div className='w-full flex flex-row my-2'>
                    <div className='w-full font-bold'>{data?.metrics?.education?.learning?.overdue} Overdue</div>
                    <div className='w-full flex flex-row text-center'>
                        <div className='w-full'>- {data?.metrics?.education?.learning?.overdueDeductions} </div>
                        <div className='w-[1%] mx-2'>/</div>
                        <div className='w-full'>{data?.metrics?.education?.learning?.overdue * 100}</div>
                    </div>
                </div>
                <div className='w-full text-center text-gray-400 font-light py-2'>
                    (Each day an assignment is overdue, 1 point is deducted from total score.)
                </div>
                <div className='w-full flex flex-row border-t-2 py-2'>
                    <div className='w-full font-bold'>Total Score</div>
                    <div className='w-full flex flex-row text-center'>
                        <div className='w-full'>{data?.metrics?.education?.learning?.totalPoints} </div>
                        <div className='w-[1%] mx-2'>/</div>
                        <div className='w-full'>{data?.metrics?.education?.learning?.totalPossiblePoints}</div>
                    </div>
                </div>
            </div>

            
        </div>
    )
}

export default ScoreCardTeamwork;