interface AvatarProps {
    first: string;
    last: string;
    avatar?: string;

}

export default function Avatar(props:AvatarProps) {
    return (
        <div className="flex items-center">
          <div>
          <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
            {
              props.avatar ? <img src={props.avatar} />  :
              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            }
          </span>
          </div>
          <div className="ml-3">
            <p className="text-md font-medium text-gray-700 group-hover:text-gray-900">{props.first} {props.last}</p>
          </div>
        </div>
      
    )
  }