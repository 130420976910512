import { Oval } from  'react-loader-spinner';
export default function Loading(){
    return <div className='h-[100%] flex flex-col justify-center items-center'>
        <Oval
            height={80}
            width={80}
            color="rgb(4 120 87)"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
        />
        <label>Loading . . .</label>
    </div>
}