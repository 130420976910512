import React, { useContext, useState, useEffect } from 'react';

import {
  Bars3Icon,
  XMarkIcon,
  UserGroupIcon,
  IdentificationIcon,
  BackspaceIcon,
  FireIcon,
  BoltIcon,
  ChartBarIcon,
  ChevronRightIcon
  

} from '@heroicons/react/24/solid';

interface StatCardProps {
  title?: string;
  icon?: string;
  value?: string;
  desc?:string;
  routeDisabled?: boolean
}
export default function StatCardSmall(props: StatCardProps | any) {
    return (
        <div className="m-2 border-solid border-2 rounded-2 border-grey-100 p-0">
          <div className='w-full flex flex-col p-4 items-center'>
              <div className='w-full flex flex-row items-center'>
                <div className="w-[15%]">
                  {props.icon}
                </div>
                <div className="pl-2 w-full font-normal text-gray-400 text-sm">{props.title}</div>
                <div className='w-[20%] font-bold'>{props.value}</div>
                {!props.routeDisabled && <ChevronRightIcon  width={20} height={20} className='w-[10%]'/>}
              </div>
              
          </div>
        </div>
      
    )
  }