import React, {useEffect, useState} from 'react';
import { Routes, Route, Outlet, Link, useParams } from "react-router-dom";
import { GetScoreCardActivity, GetScoreCardActivityForAnEmployee, InsertJobForReview, GetJobReviewStatus } from '../../utils/api';
import { ProfileContext } from '../../state/store';
import { ChevronRightIcon, CheckBadgeIcon } from '@heroicons/react/24/solid';
import Loading from '../../components/loading';
import Modal from '../../components/modal';
import Button from '../../components/button';

const CurrentProductivityColor = (value: number) => {
    if (value >= 100) {
        return "text-green-400";
    } else if (value === 0) {
        return "text-gray-400";
    } else if (value >= 90 && value < 100) {
        return "text-yellow-400";
    } else {
        return "text-red-400";
    }
};

const JobElement = (data: any, setFn: (e: any) => void) => {
    return <Link to={`/profile/activity/${data?.job_date}`}>
        <div className="m-2 border-solid border-2 rounded-2 border-grey-100 p-0">
            <div className='w-full flex flex-col p-4 items-center'>
                <div className='w-full flex flex-row items-center'>
                <div className={`${CurrentProductivityColor(data?.productivity_score)} w-[15%]`}>
                        <CheckBadgeIcon />
                </div>
                <div className="pl-4 w-full font-normal text-gray-400 text-sm">{data?.job_date}</div>
                <div className='w-[20%] font-semibold'>{(data?.productivity_score || 0).toFixed(2)}%</div>
                </div>
                
            </div>
        </div>
  </Link>
}

const AverageElement = (value:any, title: string) => {
    return <div className='border-b-2 border-t-2 w-[100%] text-center flex flex-col'>
        <span className='font-semibold text-black py-1'>{value}%</span>
        <span className='font-semibold text-gray-300'>{title}</span>
        
    </div>
}

const SpecificActivity = (props: any) => {
    const param = useParams();
    const context:any = React.useContext(ProfileContext);
    const [profile, setProfile] = React.useState(context.profile);
    const [data, setData]: any = React.useState({});
    const [job, setjob]: any = React.useState({});
    const [loading, setLoading]  = React.useState(true);

    const [modalForJobInFocus, setModalForJobInFocus] = React.useState(false);
    const [jobInFocus, setJobInFocus] = React.useState({});
    const [temporaryExcuseHolder, setTemporaryExcuseHolder] = React.useState<string>('');
    const [submitted, setSubmitted] = React.useState(false); 

    const getInitialData = async () => {
        const resp = await GetScoreCardActivity();
        if(resp){
            setData(resp);
            setjob(resp.metrics.jobs.filter((job: any) => job.job_date === param.date)[0]);
            const d = await GetJobReviewStatus(resp.data.id, param.date);
            if(d){
                console.log(d);
                setSubmitted(true);
            }
            setTimeout(() => setLoading(false), 1000);
        }
    }

    const getEmployeeData = async () => {
        const resp = await GetScoreCardActivityForAnEmployee(param.id);
        setProfile(resp.data.profile);
        if(resp){
            setData(resp);
            setTimeout(() => setLoading(false), 1000);
        } else {
            window.history.back();
        }
    }

    const setDataInFocus = async (data: any) => {
        setJobInFocus({'loading': true});
        setModalForJobInFocus(true);
    }

    const getJobReviewStatus = async () => {

    }

    const removeDatainFocus = async () => {
        setModalForJobInFocus(false);
        setTimeout(() => {
            setJobInFocus({});
            setTemporaryExcuseHolder('');
        }, 500)        
    }

    const saveExcuseToDatabase = async () => {
        if(temporaryExcuseHolder.length > 5){
            setLoading(true);
            const out: any = {'eid': job['eid'], 'job_date': job['job_date']};
            out['reason'] = temporaryExcuseHolder;
            await InsertJobForReview(out);
            window.location.reload();
        }
    }

    React.useEffect(() => {
        if(param.id){
            getEmployeeData();
        } else {
            getInitialData();
        }
    }, []);

    if(loading){
        return <Loading />
    }

    return (
        <React.Fragment>
            {/*<React.Fragment>
                <Modal 
                cancelModal={removeDatainFocus}
                //@ts-ignore
                title={`${Object(jobInFocus).hasOwnProperty('loading') === true ? '' : `Job on ${jobInFocus?.job_date}`}`} 
                disabled={false} 
                showModal={modalForJobInFocus} 
                showModalFn={saveExcuseToDatabase}>
                    {
                        Object(jobInFocus).hasOwnProperty('loading') === true ? <React.Fragment>
                            
                        </React.Fragment> : <React.Fragment>
                            <h1 className='my-2'>Request For Review</h1>
                            <div className='text-left'>
                                If this you feel this job should not be counted towards your score, provide a comment and your manager will review.
                            </div>
                            <textarea 
                                className='border-2 my-4 p-1 w-full h-[150px]'
                                value={temporaryExcuseHolder}
                                onChange={(e) => setTemporaryExcuseHolder(e.target.value)}
                            />
                        </React.Fragment>
                    }
                </Modal>
            </React.Fragment>*/}
            {submitted === false && <div className="w-full h-full overflow-y-scroll p-4 m:px-[10%] md:px-[30%] flex flex-col justify-between">
                <div>
                    <h1 className='my-2 text-3xl font-bold text-center'>Request for Review</h1>
                    <h1 className='my-2 text-lg font-semibold'>For job on {job.job_date}</h1>
                    <div className='text-left my-4'>
                        If this you feel this job should not be counted towards your score, provide a comment and your manager will review.
                    </div>
                    <textarea 
                        className='border-2 my-4 p-1 w-full h-[150px]'
                        value={temporaryExcuseHolder}
                        onChange={(e) => setTemporaryExcuseHolder(e.target.value)}
                    />
                </div>
                <div className='py-2 flex justify-end px-4'>
                    <Button 
                        title='Submit'
                        onClick={temporaryExcuseHolder.length > 5 === true ? saveExcuseToDatabase : () => {}}
                        color={temporaryExcuseHolder.length > 5 === true ? 'red' : 'gray'}
                    />
                </div>
            </div>}
            {
                submitted === true && <div className="w-full h-full overflow-y-scroll p-4 m:px-[10%] md:px-[30%] flex flex-col justify-center items-center"> 
                    <div className='text-2xl text-gray-400'>
                        Job is in review.
                    </div>
                    <div className='my-8'>
                        <Button title='Go Back' onClick={() => {
                            window.history.back();
                        }} />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default SpecificActivity;

/****
 * standalone list item: title and value
 * actionable list item: title, value, mod
 * 
 * 
 * 
 * 
 */