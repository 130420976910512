import React, { useContext, useState, useEffect } from 'react';

interface StatCardProps {
  title?: string;
  icon?: string;
  value?: string;
  desc?:string;
  unit?: string;
}
export default function StatCard(props: StatCardProps | any) {
    return (
        <div className="m-2 border-solid border-2 rounded-2 border-grey-100 p-0">
          <div className='flex flex-row items-center p-4'>
            <div className="w-[15%]">
              {props.icon}
            </div>
            <div className="flex flex-col ml-4 w-full">
              <div className='font-bold text-2xl'>{props.value}{props.unit}</div>
              <div className="font-normal text-gray-400 text-sm">{props.title}</div>
              {props.desc &&  <div className="font-thin text-gray-400 text-xs ">{props.desc}</div>}
            </div>
          </div>
        </div>
      
    )
  }