import React, {useEffect } from "react";
import { Oval } from  'react-loader-spinner';
import { toast } from 'react-toastify';


export default function Logout(){
    useEffect(() => {
      try {
        localStorage.clear();
        toast.info('Login out', {
          autoClose: 500,
          toastId: 2
        });
      } catch (_) {

      } finally {
        setTimeout(() => window.location.href = '/', 1000);
      }

    }, []); 

    return (
        <div className='flex h-[100%] flex-grow bg-white flex-col justify-center items-center '>
          <Oval
            height={80}
            width={80}
            color="rgb(4 120 87)"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />  
        <label className='text-sm font-bold text-emerald-700 p-2'>Loggin off . . .</label>
      </div>
    )
}