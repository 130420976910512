//@ts-nocheck
import Avatar from './avatar';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
  UserGroupIcon,
  IdentificationIcon,
  BackspaceIcon
} from '@heroicons/react/24/outline'
import { RouteAccess } from '../utils/routeaccess';
import { PAGE_ROUTES } from '../routes';
import { HOME_URL } from '../App';


export default function SmartHeader(props:any) {
  const dt = new Date();
  let options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  }
  return (
    <Popover className="relative bg-white z-[500]">
      <div className="mx-auto max-w-7xl px-6 z-[500]">
        <div className="flex items-center justify-between py-4 md:justify-start md:space-x-10 z-[500]">
          <div className="flex flex-col font-bold justify-between">
            <a href={HOME_URL}>
              <button className="inline-flex items-center justify-center items-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <BackspaceIcon className="pr-2 h-6 w-6" aria-hidden="true" />
                <button>T&B</button>
              </button>
            </a>
          </div>
          <div className="-my-2 -mr-2 flex w-screen flex-col items-end ">
            
            <Popover.Button className=" font-bold inline-flex items-center justify-center items-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <button>Menu</button>
              <Bars3Icon className="pl-2 h-6 w-6" aria-hidden="true" />
            </Popover.Button>
            <div className='font-bold text-[12px] text-gray-400'>{dt.toLocaleDateString("en-US", options)}</div>
          </div>
        </div>
        <div className='h-[40px] flex flex-row justify-between border-b-2 border-gray-300 mb-2'>
          <div className='font-bold text-xs'>
            <Avatar first={props.profile.first} last={props.profile.last} />
          </div>
          <div>
          <div className='flex items-center py-2'>
              <div className='flex flex-row justify-center items-center pr-3'>
                  <UserGroupIcon className="h-6 w-6 text-emerald-700" aria-hidden="true" />
              </div>
              <h1 className='text-xs'>Team {props.profile.team}</h1>
          </div>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 right-0 transform p-2 transition w-screen sm:w-[300px]">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  T&B
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
                <div className="py-6 px-0">
                    <Avatar first={props.profile.first} last={props.profile.last} />
                    <div className='grid grid-cols-2 gap-y-4 gap-x-8'>
                        <div className='flex items-center py-2'>
                            <div className='flex flex-col justify-center items-center pr-3'>
                                <UserGroupIcon className="h-6 w-6 text-emerald-700" aria-hidden="true" />
                                <label className='text-xs font-bold'>Team</label>
                            </div>
                            <h1>{props.profile.team}</h1>
                        </div>
                        <div className='flex items-center py-2'>
                            <div className='flex flex-col justify-center items-center pr-3'>
                                <IdentificationIcon className="h-6 w-6 text-emerald-700" aria-hidden="true" />
                                <label className='text-xs font-bold'>Role</label>
                            </div>
                            <h1>{props.profile.position}</h1>
                        </div>
                    </div>
                </div>
                <div className="mt-0 border-t-2 py-6">
                    <nav className="grid gap-y-8">
                    {PAGE_ROUTES.map((item) => (
                        <Link
                            key={item.name}
                            to={RouteAccess(item.name, item.href, props.profile.role)}
                            className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                        >
                        <item.icon className="h-6 w-6 flex-shrink-0 text-emerald-700" aria-hidden="true" />
                        <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                        </Link>
                    ))}
                    </nav>
                </div>
            </div>

          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}