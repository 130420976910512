import { toast } from 'react-toastify';


export const Loading = (str?: string) => {
    return toast.info(str ? str : 'Loading . . .', {
        autoClose: 1000,
        toastId: 3
    });
} 

export const SavingToast = (str?: string) => {
    return toast.info(str ? str: "Saving. Please wait...", {
        autoClose: 1000,
        toastId: 30
    });
} 

export const SavingErrorToast = (str?: string) => {
    return toast.error(str ? str : "Error. Please try again.", {
        autoClose: 1000,
        toastId: 31
    });
} 

export const SavingSuccessToast = (str?: string) => {
    return toast.success(str ? str : "Saved!", {
        autoClose: false,
        toastId: 32,
        closeButton: <div className='flex flex-row'>
                        <button className='mx-2 my-2 bg-red-300 px-4 py-2 text-white font-bold' onClick={
            () => {
                window.history.back();
                toast.dismiss();
            }
        }>Back</button>
            <button className='mx-2 my-2 bg-green-500 px-4 py-2 text-white font-bold' onClick={
            () => {
                window.location.reload();
                toast.dismiss();
            }
        }>Refresh</button>
        </div>
    });
}


