export default function Button(props: {
    title: string;
    onClick?: () => void;
    width?: number;
    color?: string;
    brandColor?: string;
    icon?: any;
    disabled?: boolean;
}){
    return(
        <button
          onClick={props.onClick}
          type="button"
          className={`flex flex-row items-center justify-center rounded-md border border-transparent ${props.color !== undefined ? `bg-${props.color}-500 hover:bg-${props.color}-700` : 'bg-emerald-600 hover:bg-emerald-700'}  px-4 py-2 text-xs font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-emeral-500 focus:ring-offset-2 ${props.width !== undefined  ? `w-[${props.width}px] text-center` : 'inline-flex items-center '} ${props.brandColor !== undefined ? `bg-${props.brandColor} ` : ''}`}
      >
        {props.title}
        {props.icon ? props.icon : null}
      </button>
    )
}