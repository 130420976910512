import history from "../utils/history";
import { BackspaceIcon } from '@heroicons/react/24/outline';

const BackControl = 'w-100 cursor-pointer flex ify-start items-center';
const ContainerLayout = 'w-[100%] h-[100%] flex flex-col justify-center items-center font-semibold mb-70 ';


export const BackWrapper = (props:any) => {
    return (
      <div className="w-[100%] h-full">
        <div onClick={() => history.back()} className={BackControl}><BackspaceIcon className='w-8 h-8 text-brandGreen'/> Back</div>
        <div className={ContainerLayout}>
          {props.children}
        </div>
      </div>
    )
  }