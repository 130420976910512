import { Link } from 'react-router-dom';
import { CardButtonStyle } from './cardroute';

export const CardLink = (props:{
    link: string;
    title: string
}) => {
    return (
        <div>
            <a style={{ textDecoration: 'none' }} href={props.link}>
                <div className={CardButtonStyle}>
                    <h2>{props.title}</h2>
                </div>
            </a>
        </div>
    )
}