//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { IdentificationIcon } from '@heroicons/react/24/outline';
import Loading from '../components/loading';
import { API_URL } from '../App';
import AvatarLG from '../components/avatarlg';
import { formatPhoneNumber } from 'react-phone-number-input';
import { BackWrapper } from '../components/backwrapper';

const people = [
  {
    name: 'Person 1',
    imageUrl: 'person1.jpg',
    role: 'Team Leader',
    phone: '(123) 456-7890',
  },
  {
    name: 'Person 2',
    imageUrl: 'person2.jpg',
    role: 'Assistant Team Leader',
    phone: '(987) 654-3210',
  },
  {
    name: 'Person 3',
    imageUrl: 'person3.jpg',
    role: 'Team Member',
    phone: '(555) 123-4567',
  },
  {
    name: 'Person 4',
    imageUrl: 'person4.jpg',
    role: 'Team Member',
    phone: '(888) 999-0000',
  },
];


interface Person {
  name: string;
  imageUrl: string;
  role: string;
}

const formatThePhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    return formatPhoneNumber(`+1${cleaned}`);
};

const ContactCard: React.FC<{ person: Person }> = ({ person }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCall = () => {
    closeModal();
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.stopPropagation(); 
    closeModal();
  };

  return (
    <div key={`${person.first}${person.last}`} className='border-2 rounded-[12px] p-2' onClick={() => handleClick()}>
        <div className=''>
            <AvatarLG first={person.first} last={person.last} />
        </div>
        <div className='h-[20%]'>
            <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">{person.first} {person.last}</h3>
            <p className="text-sm leading-6 text-gray-600">{person.position}</p>
            <p className="text-sm leading-6 text-gray-600">Phone: {formatThePhoneNumber(person['work#'])}</p>
        </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-4 rounded-lg">
            <p>You are about to call: {person.first} {person.last}</p>
            <div className="mt-4 flex justify-end">
              <button
                type="button"
                className="px-4 py-2 text-white bg-brandGreen rounded-md hover:bg-blue-600"
                onClick={() => handleCall()}
              >
                Call
              </button>
              <button
                type="button"
                className="ml-2 px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
                onClick={(e) => handleCancel(e)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const Contacts: React.FC = (props:any) => {
    const [contactList, setContactList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTeamleadContact(props.user.team);
      }, [props.user.team]);
    
      async function getTeamleadContact(type) {
        const tokenData = localStorage.getItem('tnbAccess');

        try {
          const response = await fetch(`${API_URL}/api/v1/getcontact/${type}`, {
            method: 'GET',
            headers: {
              Authorization: JSON.parse(tokenData).token,
            },
          });
          if (response.status === 200) {
            
            const result = await response.json();
            setContactList(result.data);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setTimeout(() => setLoading(false), 1000);
        }
      }
  return (
    <React.Fragment>
      <BackWrapper>
         {loading ? (
        <Loading />) :(
            <div className='w-full overflow-scroll'>
            <div className="mx-2 mb-10">
            <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
              <div className="mx-auto max-w-2xl">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Your Team</h2>
                <p className="mt-4 text-lg leading-8 text-gray-600">
                  Do you have any questions? Don't hesitate to ask us:
                </p>
              </div>
              <div className="mx-auto mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-x-8 gap-y-16 max-w-2xl">
                {contactList.map((person) => (
                  <ContactCard key={`${person.first}${person.last}`} person={person} />
                ))}
              </div>
            </div>
          </div>
          </div>
        )}
        </BackWrapper>
    </React.Fragment>
  );
};
