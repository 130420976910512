import React, { useState } from 'react';
import {
  ChevronRightIcon,
  ChevronDownIcon
} from '@heroicons/react/24/solid';

interface StatCardProps {
  title?: string;
  icon?: string;
  value?: string;
  desc?: string;
  routeDisabled?: boolean;
  isAccordion?: boolean;  // New prop to determine if this should behave as an accordion
}

export default function StatCardSmallAccordian(props: StatCardProps | any) {
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to toggle accordion state
  const handleToggle = () => {
    if (props.isAccordion) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div className="m-2 border-solid border-2 rounded-2 border-grey-100 p-0">
      <div 
        className='w-full flex flex-col p-4 items-center cursor-pointer' 
        onClick={handleToggle}
      >
        <div className='w-full flex flex-row items-center'>
          <div className="w-[15%]">
            {props.icon}
          </div>
          <div className="pl-2 w-full font-normal text-gray-400 text-sm">{props.title}</div>
          <div className='w-[20%] font-bold'>{props.value}</div>
          {true && (
            props.isAccordion ? (
              isExpanded ? (
                <ChevronDownIcon width={20} height={20} className='w-[10%]' />
              ) : (
                <ChevronRightIcon width={20} height={20} className='w-[10%]' />
              )
            ) : (
              <ChevronRightIcon width={20} height={20} className='w-[10%]' />
            )
          )}
        </div>
        {props.isAccordion && isExpanded && (
          <div className='w-full pt-2'>
            {/* Additional content when accordion is expanded */}
            <p className='text-gray-500 text-xs'>{props.desc}</p>
          </div>
        )}
      </div>
    </div>
  );
}
