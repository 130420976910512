//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { IdentificationIcon } from '@heroicons/react/24/outline';
import Loading from '../components/loading';
import { API_URL } from '../App';
import AvatarLG from '../components/avatarlg';
import { formatPhoneNumber } from 'react-phone-number-input';
import { BackWrapper } from '../components/backwrapper';
import { CardButtonStyle } from '../components/cardroute';


const messages = {
  eng: {
    type: 'eng',
    msg1: 'If you are having problems as an employee of Teed & Brown, we want to know!',
    msg2: 'Most of the time, submitting a report is the best course of action. The reports are checked several times per week. Our HR Director will respond to you quickly.',
    msg3: 'If you have just a quick question that you believe can be answered easily, calling or texting may be easiest. Just click the button below!',
    buttonTitles: ['Submit Report', 'Cell - Phone', 'Text - Phone']
  },
  esp: {
    type: 'esp',
    msg1: 'Si tiene problemas como empleado de Teed & Brown, ¡queremos saberlo!',
    msg2: 'La mayoría de las veces, enviar un reporte es el mejor curso de acción. Los informes se revisan varias veces por semana. Nuestro Director de Recursos Humanos le responderá rápidamente.',
    msg3: 'Si solo tiene una pregunta rápida que cree que se puede responder fácilmente, llamar o enviar mensajes de texto puede ser más fácil. ¡Simplemente haga clic en el botón de abajo!',
    buttonTitles: ['Enviar Reporte', 'Llamar - Teléfono', 'Texto - Teléfono']
  },
  por: {
    type: 'por',
    msg1: 'Se você está tendo problemas como funcionário da Teed & Brown, queremos saber!',
    msg2: 'Na maioria das vezes, enviando um relatório é o melhor curso de ação. Os relatórios são verificados várias vezes por semana. Nosso Diretora de HR responderá a você rapidamente.',
    msg3: 'Se você tiver apenas uma pergunta rápida que acredita que pode ser respondida facilmente, ligar ou enviar mensagens de texto pode ser mais fácil. Basta clicar no botão abaixo!',
    buttonTitles: ['Enviar Reporte', 'Llamar - Teléfono', 'Texto - Teléfono']
  },
};

export const HR: React.FC = (props:any) => {
    const [detailView, setDetailView] = useState(false);
    const [languageSelection, setLanguageSelection] = useState(0);
    const [viewData, setViewData] = useState(messages[props.language])

  return (
    <React.Fragment>
        <BackWrapper>
          <div className='h-[100%] w-[100%] sm:w-[500px] font-normal'>
            <div className='flex flex-col items-center'>
              <p className='m-2'>
                  {viewData.msg1}
              </p>
              <p className='m-2'>
                  {viewData.msg2}
              </p>
              <button className={`m-4 ${CardButtonStyle}`}>
                {viewData.buttonTitles[0]}
              </button>
            </div>
            <div className='flex flex-col items-center'>
              <p className='m-2'>{viewData.msg3}</p>
              <button className={`m-1 ${CardButtonStyle}`}>
                {viewData.buttonTitles[1]}
              </button>
              <button className={`m-1 ${CardButtonStyle}`}>
                {viewData.buttonTitles[2]}
              </button>
            </div>
          </div>
        </BackWrapper>
    </React.Fragment>
  );
};
