import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, Outlet } from "react-router-dom";
import { ProfileContext, ViewContext } from '../state/store';
import { CardLink } from '../components/cardlink';
import { CardRoute } from '../components/cardroute';
import { LayoutMain } from '../components/layoutmain';
import { BackWrapper } from './backwrapper';
import ProtectedRoute from './protectedroute';

interface CompType {
    type: string;
    title: string;
    link: string;
    key?: number;
    altLink?: string;
    parent?: string;
    role?: string[];
    conditional?: boolean;
    secured?: boolean;
}

const Components: Record<string, React.ComponentType<any>> = {
    link: CardLink,
    route: CardRoute
};

export default function Menu(props: {
    list: CompType[]
}) {
    const profile = useContext(ProfileContext);
    return (
        <React.Fragment>
            <BackWrapper>
                <div className='overflow-scroll h-[100%] w-[100%] flex flex-col justify-center items-center'>
                    {props.list.map((mark: CompType, index: number) => {
                        const { type, title, link, conditional, role, altLink, secured } = mark;
                        const Component = Components[type];
                        if(!secured){
                            if(!conditional){
                                return <Component key={index} title={title} link={link} />;
                            } else {
                                return <Component 
                                    key={index} 
                                    title={title} 
                                    //@ts-ignore
                                    link={role?.includes(profile.profile.role) ? link :  altLink} />
                            }
                        } else {
                            //@ts-ignore
                            return role?.includes(profile.profile.role)  && <Component key={index} title={title} link={link} />;
                        }
                    })}
                </div>
            </BackWrapper>
        </React.Fragment>
    );
}