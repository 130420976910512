import React, { useState, useEffect } from 'react';
import logo from '../assets/logo.png';
import { API_URL } from '../App';
import { toast } from 'react-toastify';


interface LoginProps {
  state: boolean;
  changeLoggedInState: (loggedIn: boolean, token?: string) => void;
}

const Login: React.FC<LoginProps> = (props) => {
  const helpDesk = '19718010045';
  const [loggedIn, setLoggedIn] = useState(props.state);
  const [passError, setPassError] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  async function ExecuteLogIn(ob: { name: string; pass: string }) {
    try {
        const response = await fetch(`${API_URL}/api/v1/login`, {
          method: 'POST',
          body: JSON.stringify(ob),
          headers: { 'Content-Type': 'application/json' },
        });
  
        if (response.status === 200) {
          const result = await response.json();
          if (result) {
            console.log('pass');
            // ... successful login ...
            toast.success('Login successful.', {toastId: 1});
            localStorage.setItem('tnbAccess', JSON.stringify(result.data));
            props.changeLoggedInState(true, result.data.token);
            setLoggedIn(true);
          } else {
            console.log('fail');
            // ... login failure ...
            toast.error('Login failed. Please try again.', {toastId: 0});
            props.changeLoggedInState(false);
            setLoggedIn(false);
          }
        } else {
            console.log('fail');
            // ... login failure ...
            toast.error('Login failed. Please try again.', {toastId: 0});
            //props.changeLoggedInState(false);
            setLoggedIn(false);
        }
      } catch (error) {
        console.error('Login error:', error);
      }
  }

  function handleLogin() {
    if (password.trim().length < 4 || password.trim().length > 4) {
        // Password is too short, set passError to true
        setPassError(true);
        return; // Exit the function without executing the login logic
    }
    
      // Reset passError to false if the password is valid
    setPassError(false);
        const credentials = {
        name: username,
        pass: password,
    };
    console.log(credentials);
    ExecuteLogIn(credentials);
  }

  useEffect(() => {
    if (username.trim().length === 0 || password.trim().length === 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [username, password]);

  return (
    <div className="h-full w-full bg-white flex flex-col justify-center items-center content-center pt-5">
        <div className="inner-component w-72 flex flex-col justify-center items-center rounded box-shadow-lg border-2 pb-5">
            <img src={logo} alt="Tweed & Brown Logo" className="mt-0 w-48 h-48" />

            <div className="mb-6">
                <div className="flex flex-col">
                <label htmlFor="username" className="block text-lg font-semibold text-gray-700">
                    Username
                </label>
                <input
                    type="text"
                    id="username"
                    className="border border-gray-300 rounded px-3 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                </div>
            </div>
            <div className="mb-6">
                <label htmlFor="password" className="block text-lg font-semibold text-gray-700">
                    Password
                </label>
                <div className="flex flex-col">
                    <div className="flex">
                    <input
                        type="password"
                        id="password"
                        className={`border border-gray-300 rounded px-3 py-2 mt-1 focus:outline-none ${
                        passError ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-indigo-500'
                        }`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    </div>
                </div>
            </div>

            {passError ? (
                    <div className="mb-6 text-center h-[50px]">
                        <p className="text-red-500 text-xs">Please enter a valid password (minimum 4 characters).</p>
                    </div>
                ) : <div className="mb-6 h-[50px]"></div>
            }
            <button
                className={`px-4 py-2 text-lg font-semibold text-white rounded ${
                    disableButton ? 'bg-gray-500 cursor-not-allowed' : 'bg-brandGreen'
                }`}
                onClick={handleLogin}
                disabled={disableButton}
            >
                Login
            </button>
        </div>
        {/*<a
            href={`sms:${helpDesk}&body=${encodeURIComponent("I'm having issues accessing the T&B employee app.")}`}
            className="contact-button mt-10 inline-block px-4 py-2 bg-red-500 text-white text-decoration-none rounded font-semibold"
        >
            Contact Support
              </a>*/}
    </div>
    );
};

export default Login;

