//@ts-nocheck
import React, {useEffect, useState} from 'react';
import { Routes, Route, Navigate, useParams, useLocation, BrowserRouter as Router } from "react-router-dom";
import Layout from './components/layout';
import Login from './components/login';
import Loading from './components/loading';
import Main from './components/Main';
import { checkCreds } from './utils/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProfileContext } from './state/store';
import Bookmarks from './pages/bookmarks';
import Logout from './pages/logout';
import Menu from './components/menu';
import { Contacts } from './views/contacts';
import { HR } from './views/hr';
import { InstructionButton } from './views/instructionButton';
import { Certifications } from './views/certifications';
import ProtectedRoute from './components/protectedroute';
import Profile from './pages/profile';
import PointsScoreCard from './pages/scorecard/points';
import ScoreCardActivity from './pages/scorecard/activity';
import ScoreCardTeamwork from './pages/scorecard/teamwork';
import ScoreCardSafety from './pages/scorecard/safety';
import ScoreCardStanding from './pages/scorecard/standing';
import EmployeeList from './pages/employee-list';
import SpecificActivity from './pages/scorecard/singleactivity';
import EmployeeReviewForm from "./pages/forms/review.tsx";
import GeneralForm from './pages/forms';

export const API_URL : string = process.env.REACT_APP_API ?? '';
export const PERFORMANCE_APP : stirng = process.env.REACT_APP_PERFORMANCE_APP ?? '';
export const HOME_URL : string = '/';
//export const LOCAL_STORE_AXX = JSON.parse(localStorage.getItem('tnbAccess')).token;



/*function App() {
  const [loading, setloading] = useState(false);
  const [profile, setProfile] = useState({});
  const location = useLocation();

  if(loading){
    return (
      <div className='fixed top-0 bottom-0 w-screen h-screen bg-white flex flex-col justify-center items-center '>
        <Loading />
        <label className='text-sm font-bold text-emerald-700 p-2'>Loading Profile . . .</label>
      </div>
    )
  }
  return (
    <Layout profile={profile}>
      <Routes>
      <Route path="/" element={
          <React.Fragment>
            hello wrodl
          </React.Fragment>
        } />
      </Routes>
    </Layout>
  );
}*/

export const getLoginToken = () => {
  const tnbAccess = localStorage.getItem('tnbAccess');
  const parsedData = tnbAccess ? JSON.parse(tnbAccess) : null;
  return parsedData?.token || '';
  //return JSON.parse(localStorage.getItem('tnbAccess')).token ?? '';
}

const appRoutes = [
  {
    type: 'route',
    title: 'Company',
    link: '/lawn/company',
    parent: '/lawn',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Customers',
    link: '/lawn/customer',
    parent: '/lawn',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Equipment',
    link: '/lawn/equipment',
    parent: '/lawn',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Materials',
    link: '/lawn/materials',
    parent: '/lawn',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Manager Reports',
    link: '/lawn/managers',
    parent: '/lawn',
    role: ['Manager', 'Global'],
    conditional: false,
    secured: true
  },
  {
    type: 'link',
    title: 'Daily Coach',
    link: `${PERFORMANCE_APP}signin/${getLoginToken()}`,
    parent: '/lawn/company',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'End-Of-Day Checklist',
    link: 'https://shorturl.at/sxER4',
    altLink: 'https://docs.google.com/document/d/e/2PACX-1vQe6BEbL945sVnw_D6xh0z-HnfanSPuM8j5UL7jVyxWM8NBGI7NFeTH8UDtb0OeCL3mp2dbrQf6x0-v/pub',
    parent: '/lawn/company',
    role: ['Manager', 'Global', 'Supervisor'],
    conditional: true,
    secured: false
    
  },
  {
    type: 'route',
    title: 'Employees',
    link: '/lawn/company/employees',
    parent: '/lawn/company',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Refer Potential Employee',
    link: 'https://forms.monday.com/forms/231e326c1d514dca2feabe6c6b830517',
    parent: '/lawn/company/employees',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Contacts',
    link: '/lawn/company/employees/contacts',
    parent: '/lawn/company/employees',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Future Lesson Ideas',
    link: 'https://forms.monday.com/forms/10359be0bb40164c37e631c5d100b119?r=use1',
    parent: '/lawn/company/employees',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Daily Helper Feedback',
    link: 'https://forms.monday.com/forms/ed022e816cdee2c676c060948f86767b?r=use1',
    parent: '/lawn/company/employees',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Office Requests / Feedback',
    link: '/lawn/company/officereqs',
    parent: '/lawn/company',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Order Forms',
    link: '/lawn/company/officereqs',
    parent: '/lawn/company/officereqs',
    role: ['Manager', 'Supervisor', 'Global'],
    conditional: false,
    secured: true
  },
  {
    type: 'link',
    title: 'Reimbursement Request',
    link: 'https://forms.monday.com/forms/f7cfa166eff454454641a650afbf2cf4?r=use1',
    parent: '/lawn/company/officereqs',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Submit CC Receipt',
    link: 'https://forms.monday.com/forms/e879c11c323cfcb20978a6e961f840c9?r=use1',
    parent: '/lawn/company/officereqs',
    role: ['Manager', 'Supervisor', 'Global'],
    conditional: false,
    secured: true
  },
  {
    type: 'link',
    title: 'Employee Manual',
    link: 'https://forms.monday.com/forms/e879c11c323cfcb20978a6e961f840c9?r=use1',
    parent: '/lawn/company/officereqs',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Suggestion Box',
    link: 'https://forms.monday.com/forms/66374f4ff9b55740583653103b2eec9b?r=use1',
    parent: '/lawn/company/officereqs',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Accident Report',
    link: 'https://forms.monday.com/forms/b3f4376f8fa3708b96760c60ba34838b?r=use1',
    parent: '/lawn/company/officereqs',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Human Resources (HR)',
    link: '/lawn/company/hr',
    parent: '/lawn/company',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Contact Human Resources',
    link: '/lawn/company/hr/eng',
    parent: '/lawn/company/hr',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Entre em contacto com Recursos Humanos',
    link: '/lawn/company/hr/por',
    parent: '/lawn/company/hr',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Contacto Recursos Humanos',
    link: '/lawn/company/hr/esp',
    parent: '/lawn/company/hr',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'New Potential Client',
    link: '/lawn/customer/referral',
    parent: '/lawn/customer',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Customer Requests',
    link: 'https://forms.monday.com/forms/ef7b418dbda19add3356cdab6ca12cfb?r=use1',
    parent: '/lawn/customer',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Job Already Done',
    link: '/lawn/customer/jobdone',
    parent: '/lawn/customer',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Dirty Lawn',
    link: 'https://forms.monday.com/forms/24042bc64a8635ba257d11dc77726f1b?r=use1',
    parent: '/lawn/customer',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Report Equipment Breakdown',
    link: 'https://forms.monday.com/forms/207f47f499689ee896c1e87ad76bd834?r=use1',
    parent: '/lawn/equipment',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Purchase New Equipment',
    link: 'https://www.surveymonkey.com/r/3BF2TTZ',
    parent: '/lawn/equipment',
    role: ['Supervisor', 'Manager', 'Global'],
    conditional: false,
    secured: true
  },
  {
    type: 'link',
    title: 'Truck Cleaning',
    link: 'https://forms.monday.com/forms/755c709016fed9b9abd1ad361289bbd8?r=use1',
    parent: '/lawn/equipment',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Damage Report',
    link: 'https://forms.monday.com/forms/a3cd01d92e48c6deb025df484b964c65?r=use1',
    parent: '/lawn/equipment',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Questions/Problems',
    link: '/lawn/materials/questions',
    parent: '/lawn/materials',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Place Order',
    link: '/lawn/materials/order',
    parent: '/lawn/materials',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Helena',
    link: 'https://www.surveymonkey.com/r/WMDRZLJ',
    parent: '/lawn/materials/order',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'F.M. Brown',
    link: 'https://www.surveymonkey.com/r/B85PSNQ',
    parent: '/lawn/materials/order',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Winfield',
    link: 'https://www.surveymonkey.com/r/LTH7XWC',
    parent: '/lawn/materials/order',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Receive Delivery',
    link: 'https://forms.monday.com/forms/dc2d2d4acb19f7c0e5c8399c427383a0?r=use1',
    parent: '/lawn/materials',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Sale/Cancel Report',
    link: 'https://docs.google.com/document/d/e/2PACX-1vSu41Dv7Lnl3o_5MXyXPques0B1rTBZeTpuG1zdB6zExQsqKmpAKrX2L6qhus_s1kIGLGDrbR7my6hT/pub',
    parent: '/lawn/managers',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Gasoline Use',
    link: 'https://docs.google.com/document/d/e/2PACX-1vRJvb-0pzQ5otkG5BUHE1hCQc0hbMYm3FdOb0FL_L5o_D6fTt3nzuSJscygO7FK0xO49vZQuHhNqnFZ/pub',
    parent: '/lawn/managers',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Employee Production',
    link: 'https://drive.google.com/drive/folders/1ggkEIq3eQYribhUMQa8u-0YfesU3ozCY?usp=sharing',
    parent: '/lawn/managers',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'Schedule Go Back',
    link: 'https://forms.monday.com/forms/b7a1f785a02000c16db6cf5d3ba14b91?r=use1',
    parent: '/irrigation',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'link',
    title: 'New Proposal Request',
    link: 'https://forms.monday.com/forms/8436112679a381f348c53f19f64e78a2?r=use1',
    parent: '/irrigation',
    role: [],
    conditional: false,
    secured: false
  }
]

const managerForms = [
  {
    type: 'route',
    title: 'Note Review Completed',
    link: '/managers/review',
    parent: '/managers',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Report Quality Control Issue',
    link: '/managers/quality-control',
    parent: '/managers',
    role: [],
    conditional: false,
    secured: false
  },
  {
    type: 'route',
    title: 'Award / Deduct Points',
    link: '/managers/points',
    parent: '/managers',
    role: [],
    conditional: false,
    secured: false
  },
]


function AssignAccess(profile){
  const lawn_teams = [
    'L - Team 1',
    'L - Team 2',
    'L - Team 3',
    'L - Team 4',
    'L - Team 5',
  ];

  const irr_teams = [
    'Irr - Bethel',
    'Irr - Bridgeport'
  ];

  const worker_roles = [
    'Supervisor',
    'Entry Level / Helper'
  ];


  if(lawn_teams.includes(profile.team) && worker_roles.includes(profile.role)){
    return {
      "profile": true,
      "activity": true,
      "learning": true,
      "safety": false,
      "standing": false
    }
  }

  if(irr_teams.includes(profile.team) && worker_roles.includes(profile.role)){
    return {
      "profile": false,
      "activity": false,
      "learning": true,
      "safety": false,
      "standing": false
    }
  }

  if(['Office', 'Global', 'Manager'].includes(profile.role)){
    return {
      "profile": false,
      "activity": false,
      "learning": false,
      "safety": false,
      "standing": false
    }
  }
}

function App() {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState('');

  const [profile, setProfile] = useState({});

  useEffect(() => {
    const initializeApp = async () => {
      try {
        const tokenData = JSON.parse(localStorage.getItem('tnbAccess'));
        if (Object.keys(tokenData).length > 0) {
          await verifyToken(tokenData.token);
          const access = AssignAccess(tokenData.profile);
          setProfile({...tokenData.profile, access});
        } else {
          console.log('no data in local storage');
        }
      } catch (error) {
        localStorage.clear();
        setLoggedIn(false);
        setLoading(false);
        toast.error('An error occurred while checking credentials.', {toastId: 0}
        );
      } finally {
        setLoading(false);
      }
    };

    initializeApp();
  }, []);

  const verifyToken = async (token) => {
    const tokenData = localStorage.getItem('tnbAccess');
    try {
      const response = await fetch(`${API_URL}/api/v1/login/checkcreds`, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setLoggedIn(true);
        const access = AssignAccess(JSON.parse(tokenData).profile);
        setProfile({...JSON.parse(tokenData).profile, access});
      } else {
        localStorage.clear();
        setLoggedIn(false);
      }
    } catch (error) {
      console.log(error);
      localStorage.clear();
      window.location.reload(false);
    } finally {
      setLoading(false);
    }
  };

  const handleLoggedInStateChange = async (value, tokenData) => {
    if(value){
      await verifyToken(tokenData);
      
    } else {
      logout();
    } 
  };

  const handleUserInfoStateChange = (value) => {
    setUserData(value);
  };

  const logout = () => {
    localStorage.clear();
    toast.info('Login out', {
      autoClose: 900,
      id: 1
    });
    setTimeout(() => window.location.href = '/', 1000);
  }

  
  return (
    <ProfileContext.Provider value={{ profile }}> 
      <div className="App w-screen h-screen">
        {loading ? (
          <Loading />
        ) : !loggedIn ? (
          <Login
            state={loggedIn}
            changeLoggedInState={handleLoggedInStateChange}
            toast={toast}
          />
        ) : (
          <Layout profile={profile}>
            <Routes>
              <Route path="/" element={profile.access.profile? <Navigate to="/profile" /> : <Navigate to="/menu" />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/points" element={<PointsScoreCard />} />
              <Route path="/profile/activity" element={<ScoreCardActivity />} />
              <Route path="/profile/activity/:date" element={<SpecificActivity />} />
              <Route path="/profile/learning" element={<ScoreCardTeamwork />} />
              <Route path="/profile/safety" element={<ScoreCardSafety />} />
              <Route path="/profile/standing" element={<ScoreCardStanding />} />
              <Route path="/profile/forms/safety" element={
                  <GeneralForm for="safety_v1" />
              }/>
              <Route path="/menu" element={<Bookmarks />} />
              <Route path="/lawn" element={<Menu list={appRoutes.filter((item) => item.parent === '/lawn')}/>}/>
              <Route path="/lawn/company" element={<Menu list={appRoutes.filter((item) => item.parent === '/lawn/company')}/>} />
              <Route path="/lawn/company/employees" element={<Menu list={appRoutes.filter((item) => item.parent === '/lawn/company/employees')}/>} />
              <Route path='/lawn/company/employees/contacts' element={<Contacts user={profile}/>}/>
              <Route path='/lawn/company/hr' element={<Menu list={appRoutes.filter((item) => item.parent === '/lawn/company/hr')}/>}/>
              <Route path='/lawn/company/hr/eng' element={<HR language={'eng'}/>}/>
              <Route path='/lawn/company/hr/esp' element={<HR language={'esp'}/>}/>
              <Route path='/lawn/company/hr/por' element={<HR language={'por'}/>}/>
              <Route path="/lawn/company/officereqs" element={<Menu list={appRoutes.filter((item) => item.parent === '/lawn/company/officereqs')}/>} />
              <Route path="/lawn/customer" element={<Menu list={appRoutes.filter((item) => item.parent === '/lawn/customer')}/>} />
              <Route path="/lawn/customer/referral" element={<InstructionButton 
                msg1={'Did you meet someone who is interested in using Teed & Brown? GREAT! Just click this button and fill out the information. We’ll take it from there. IF they sign up, YOU get a $25 bonus in your next paycheck! Make sure they are with you so you can record their contact information properly!'}
                msg2={'Good Luck and THANK YOU!'}
                buttonTitle={'New Potential Client'}
              />} />
              <Route path="/lawn/customer/jobdone" element={<InstructionButton 
                msg1={'If you got to a job that was assigned to you, BUT you can see that it was already done by us, please click this button and fill out the form. Sometimes, jobs don’t get recorded properly so we accidentally send you out to do it again. When you fill out this form, we’ll get it corrected in our system.'}
                msg2={'Thanks! Any questions? Give your team leader a call.'}
                buttonTitle={'Customer Already Done'}
              />} />
              <Route path="/lawn/equipment" element={<Menu list={appRoutes.filter((item) => item.parent === '/lawn/equipment')}/>} />
              <Route path="/lawn/materials" element={<Menu list={appRoutes.filter((item) => item.parent === '/lawn/materials')}/>} />
              <Route path="/lawn/materials/questions" element={<Certifications />} /> 
              <Route path="/lawn/materials/order" element={<Menu list={appRoutes.filter((item) => item.parent === '/lawn/materials/order')}/>} /> 
              <Route path="/lawn/managers" element={
                <ProtectedRoute 
                  user={profile} 
                  roles={appRoutes.filter((item) => item.link === '/lawn/managers')[0]['role']}
                  children={
                    <Menu list={appRoutes.filter((item) => item.parent === '/lawn/managers')}/>
                  }
                />
              } />
              <Route path="/irrigation" element={<Menu list={appRoutes.filter((item) => item.parent === '/irrigation')}/>} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/employees-list" element={
                <ProtectedRoute user={profile} roles={['Global']}>
                  <EmployeeList />
                </ProtectedRoute>
              }/>
              <Route path="/employees-list/:id" element={
                <ProtectedRoute user={profile} roles={['Global', 'Manager', 'Office']}>
                  <Profile />
                </ProtectedRoute>
              }/>
              <Route path="/employees-list/:id/points" element={
                <ProtectedRoute user={profile} roles={['Global', 'Manager', 'Office']}>
                  <PointsScoreCard />
                </ProtectedRoute>
              }/>
              <Route path="/employees-list/:id/activity" element={
                <ProtectedRoute user={profile} roles={['Global', 'Manager', 'Office']}>
                  <ScoreCardActivity />
                </ProtectedRoute>
              }/>
              <Route path="/employees-list/:id/learning" element={
                <ProtectedRoute user={profile} roles={['Global', 'Manager', 'Office']}>
                  <ScoreCardTeamwork />
                </ProtectedRoute>
              }/>
              <Route path="/employees-list/:id/forms/review" element={
                <ProtectedRoute user={profile} roles={['Global', 'Manager', 'Office']}>
                  <GeneralForm for="review_form_v1" />
                </ProtectedRoute>
              }/>
              <Route path="/managers" element={
                <ProtectedRoute user={profile} roles={['Global', 'Manager', 'Office']}>
                  <Menu list={managerForms.filter((item) => item.parent === '/managers')}/>
                </ProtectedRoute>
              }/>
              <Route path="/managers/review" element={
                <ProtectedRoute user={profile} roles={['Global', 'Manager', 'Office']}>
                  <GeneralForm for="review_form_populated_v1" />
                </ProtectedRoute>
              }/>
              <Route path="/managers/safety" element={
                <ProtectedRoute user={profile} roles={['Global', 'Manager', 'Office']}>
                  <GeneralForm for="safety_populated_v1" />
                </ProtectedRoute>
              }/>
              <Route path="/managers/quality-control" element={
                <ProtectedRoute user={profile} roles={['Global', 'Manager', 'Office']}>
                  <GeneralForm for="quality_control_prepopulated_v1" />
                </ProtectedRoute>
              }/>
              <Route path="/managers/points" element={
                <ProtectedRoute user={profile} roles={['Global', 'Manager', 'Office']}>
                  <GeneralForm for="points_v1" />
                </ProtectedRoute>
              }/>
              <Route path="/managers/points/:id" element={
                <ProtectedRoute user={profile} roles={['Global', 'Manager', 'Office']}>
                  <GeneralForm for="points_v1_prepopulated" />
                </ProtectedRoute>
              }/>
            </Routes>
          </Layout>

          
        )}
        <ToastContainer />
      </div>
    </ProfileContext.Provider>
  );
}

export default App;


//{/*<Main userData={userData} changeUserInfoState={handleUserInfoStateChange} />*/}
//export default App;
