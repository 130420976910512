import { Link } from 'react-router-dom';

export const CardButtonStyle = `
    bg-green-800
    text-white
    p-4
    mb-5
    rounded
    text-center
    shadow-md
    cursor-pointer
    text-sm
    w-48
    h-[60px]
    flex
    justify-center
    items-center
    font-bold
`;

export const CardRoute = (props:{
    link: string;
    title: string
}) => {
    return (
        <div>
            <Link style={{ textDecoration: 'none' }} to={props.link}>
                <div className={CardButtonStyle}>
                    <h2>{props.title}</h2>
                </div>
            </Link>
        </div>
    )
}