interface AvatarProps {
    first: string;
    last: string;
    avatar?: string;
}

export default function AvatarLarge(props:AvatarProps) {
  console.log(props);
    return (
        <div className="flex flex-col items-center w-full p-2">
          <span className="inline-block w-[100px] h-[100px] overflow-hidden rounded-full bg-gray-100">
            {
              <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            }
          </span>
          <div className="ml-3 mt-5 flex flex-row ">
              <p className="text-2xl font-bold  text-gray-700 group-hover:text-gray-900">{props.first} {props.last}</p>
          </div>
        </div>
    )
  }