import { API_URL } from "../App";

//@ts-ignore
const tokenData = JSON.parse(localStorage.getItem('tnbAccess'));
//@ts-ignore
const tokenToUse = tokenData?.token;

export async function GetScoreCardActivity() {
    try {
      const response = await fetch(`${API_URL}/api/v1/user/activity`, {
        method: 'GET',
        headers: {
          Authorization: tokenToUse,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        const resp = await response.json();
        return resp; // Credentials are valid
      } else {
        return false; // Credentials are invalid
      }
    } catch (error) {
      throw new Error('An error occurred while checking credentials.'); // Throw an error if an exception occurs during the request
    }
}

export async function GetScoreCardActivityForAnEmployee(eid: any) {
  try {
    const response = await fetch(`${API_URL}/api/v1/user/activity/${eid}`, {
      method: 'GET',
      headers: {
        Authorization: tokenToUse,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      const resp = await response.json();
      return resp; // Credentials are valid
    } else {
      return false; // Credentials are invalid
    }
  } catch (error) {
    throw new Error('An error occurred while checking credentials.'); // Throw an error if an exception occurs during the request
  }
}

export async function GetFormDefaultedForSpecific(eid: any, formid: any) {
  try {
    const response = await fetch(`${API_URL}/api/v1/user/forms/${formid}/${eid}`, {
      method: 'GET',
      headers: {
        Authorization: tokenToUse,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const resp = await response.json();
      return resp; // Credentials are valid
    } else {
      return false; // Credentials are invalid
    }
  } catch (error) {
    throw new Error('An error occurred while checking credentials.'); // Throw an error if an exception occurs during the request
  }
}
export async function GetFormDefaultedForGeneral(formid: any) {
  try {
    const response = await fetch(`${API_URL}/api/v1/user/forms/${formid}`, {
      method: 'GET',
      headers: {
        Authorization: tokenToUse,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      const resp = await response.json();
      return resp; // Credentials are valid
    } else {
      return false; // Credentials are invalid
    }
  } catch (error) {
    throw new Error('An error occurred while checking credentials.'); // Throw an error if an exception occurs during the request
  }
}

export async function GetAllEmployees() {
  try {
    const response = await fetch(`${API_URL}/api/v1/getallemployees`, {
      method: 'GET',
      headers: {
        Authorization: tokenToUse,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      const resp = await response.json();
      return resp; // Credentials are valid
    } else {
      return false; // Credentials are invalid
    }
  } catch (error) {
    throw new Error('An error occurred while checking credentials.'); // Throw an error if an exception occurs during the request
  }
}

export async function TestVeizonAddEmp() {
  try {
    const response = await fetch(`${API_URL}/api/v1/assignusertovehicle`, {
      method: 'GET',
      headers: {
        Authorization: tokenToUse,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      const resp = await response.json();
      return resp; // Credentials are valid
    } else {
      return false; // Credentials are invalid
    }
  } catch (error) {
    throw new Error('An error occurred while checking credentials.'); // Throw an error if an exception occurs during the request
  }
}

export async function GetJobReviewStatus(eid: any, date:any) {
  try {
    const response = await fetch(`${API_URL}/api/v1/getjobproductivityexcuse`, {
      method: 'POST',
      headers: {
        Authorization: tokenToUse,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({eid, date})
    });

    if (response.status === 200) {
      const resp = await response.json();
      return resp; // Credentials are valid
    } else {
      return false; // Credentials are invalid
    }
  } catch (error) {
    throw new Error('An error occurred while checking credentials.'); // Throw an error if an exception occurs during the request
  }
}

export async function InsertJobForReview(obj: any) {
  try {
    const response = await fetch(`${API_URL}/api/v1/insertjobforreview`, {
      method: 'POST',
      headers: {
        Authorization: tokenToUse,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
    });

    if (response.status === 200) {
      const resp = await response.json();
      return resp; // Credentials are valid
    } else {
      return false; // Credentials are invalid
    }
  } catch (error) {
    throw new Error('An error occurred while checking credentials.'); // Throw an error if an exception occurs during the request
  }
}

export async function InsertFormResponse(objectToPush: any) {
  try {
    const response = await fetch(`${API_URL}/api/v1/user/forms/submit`, {
      method: 'POST',
      headers: {
        Authorization: tokenToUse,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(objectToPush)
    });

    const resp = await response.json();
    return resp;

    /*if (response.status === 200) {
      const resp = await response.json();
      return resp; // Credentials are valid
    } else {
      return false; // Credentials are invalid
    }*/
  } catch (error) {
    throw new Error('An error occurred while checking credentials.'); // Throw an error if an exception occurs during the request
  }
}

