import { PERFORMANCE_APP, getLoginToken } from '../App';

export const ACCESS_ROLE = ['Manager', 'Global'];


export const RouteAccess = (routename: string, link: string, role: string) => {
    const token = getLoginToken();

    switch(routename){
        case 'Production Tracker':
            return `${PERFORMANCE_APP}signin/${token}`;
        case 'Logout':
            return link;
        case 'Manager Reports':
            if(ACCESS_ROLE.includes(role)){
                return link;
            } else {
                return '/production';
            }
    }
}