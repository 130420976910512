import { PERFORMANCE_APP, getLoginToken } from '../App';
import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, Outlet } from "react-router-dom";
import { ProfileContext } from '../state/store';
import { CardLink } from '../components/cardlink';
import { CardRoute } from '../components/cardroute';
import { LayoutMain } from '../components/layoutmain';

interface CompType {
    type: string;
    title: string;
    link: string;
    key?: number;
    hide: boolean;
}

const Components: Record<string, React.ComponentType<any>> = {
    link: CardLink,
    route: CardRoute
};



export default function Bookmarks() {
    const token = getLoginToken();
    const context:any = React.useContext(ProfileContext);

    const marks: CompType[] = [
        {
            key: 0,
            title: 'Your Scorecard',
            link: `/profile`,
            type: 'route',
            hide: !context?.profile?.access?.profile || false
        },
        {
            key: 1,
            title: 'Irrigation',
            link: '/irrigation',
            type: 'route',
            hide: false
        },
        {
            key: 2,
            title: 'Lawn Care',
            link: '/lawn',
            type: 'route',
            hide: false
        },
        {
            key: 3,
            title: 'Daily Coach',
            link: `${PERFORMANCE_APP}signin/${token}`,
            type: 'route',
            hide: false
        },
        {
            key: 4,
            title: 'Manager Resources',
            link: `/managers`,
            type: 'route',
            hide: (['Global', 'Manager', 'Office'].includes(context?.profile?.role) === true ? false : true)
        },
        {
            key: 5,
            title: 'View Employee Profiles',
            link: `/employees-list`,
            type: 'route',
            hide: (['Global', 'Manager', 'Office'].includes(context?.profile?.role) === true ? false : true)
        },
    ];

    return (
        <React.Fragment>
            <div className='h-[100%] w-[100%] flex flex-col justify-center items-center'>
            {marks.map((mark: CompType, index: number) => {
                if(mark.hide === false){
                    const { type, title, link } = mark;
                    const Component = Components[type];
                    return <Component key={index} title={title} link={link} />;
                }
            })}
            </div>
        </React.Fragment>
    );
}