//@ts-nocheck
import { Route, Navigate } from 'react-router-dom';
import {
    CalculatorIcon,
    InboxIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';

export const PAGE_ROUTES = [
    {
        name: 'Production Tracker',
        description: 'Get a better understanding of where your traffic is coming from.',
        link: `/`,
        icon: CalculatorIcon,
        role: 'all'
    },
    /*{
        name: 'Manager Reports',
        description: 'Speak directly to your customers in a more meaningful way.',
        href: '/admin',
        icon: InboxIcon,
        role: ['Helper', 'Technician', 'Supervisor']
    },*/
    {
        name: 'Logout',
        description: 'Logout of app.',
        href: '/logout',
        icon: XMarkIcon,
        role: 'all'
    },
];



export const checkAccessRole = (requiredRole:string, userRole:string) => {
    // Perform your access role check logic here
    return userRole === requiredRole;
};
  // ProtectedRoute component with access role check
export const ProtectedRoute = ({ element, accessRole, userRole }) => {
    if (checkAccessRole(accessRole, userRole)) {
        return <Route element={element} />;
    } else {
      // Redirect to unauthorized page or login page
        return <Navigate to="/unauthorized" />;
    }
};