import React, {useEffect, useState} from 'react';
import { Routes, Route, Outlet, Link, useParams } from "react-router-dom";
import { GetScoreCardActivity, GetScoreCardActivityForAnEmployee } from '../../utils/api';
import { ProfileContext } from '../../state/store';
import { ChevronRightIcon, CheckBadgeIcon, TrophyIcon, ShieldCheckIcon, CircleStackIcon } from '@heroicons/react/24/solid';
import Loading from '../../components/loading';
import Modal from '../../components/modal';
import StatCard from '../../components/statCard';
import StatCardSmall from '../../components/statCardSmall';
import StatCardSmallAccordian from '../../components/statCardSmallAccordian';

const Row = (props:any) => {
    return <div className='w-full'>
        {props.el}
    </div>
}

const RowLink = (props:any) => {

    return <Link style={{ textDecoration: 'none' }} to={!props.disabled && props.link} className='w-full'>
        {props.el}
    </Link>
}

const CurrentProductivityColor = (value: number) => {
    if (value >= 100) {
        return "text-green-400";
    } else if (value === 0) {
        return "text-gray-400";
    } else if (value >= 90 && value < 100) {
        return "text-yellow-400";
    } else {
        return "text-red-400";
    }
};

const JobElement = (data: any, setFn: (e: any) => void) => {
    return <Link to={`/profile/activity/${data?.job_date}`}>
        <div className="m-2 border-solid border-2 rounded-2 border-grey-100 p-0">
            <div className='w-full flex flex-col p-4 items-center'>
                <div className='w-full flex flex-row items-center'>
                <div className={`${CurrentProductivityColor(data?.productivity_score)} w-[15%]`}>
                        <CheckBadgeIcon />
                </div>
                <div className="pl-4 w-[50%] font-normal text-gray-400 text-sm">{data?.job_date}</div>
                <div className='w-[20%] font-semibold'>{(data?.productivity_score || 0).toFixed(2)}%</div>
                <div className='w-[10%] px-6'><ChevronRightIcon  width={20} height={20} className=''/></div>
                </div>
                
            </div>
        </div>
  </Link>
}

const AverageElement = (value:any, title: string) => {
    return <div className='border-b-2 border-t-2 w-[100%] text-center flex flex-col'>
        <span className='font-semibold text-black py-1'>{value}%</span>
        <span className='font-semibold text-gray-300'>{title}</span>
        
    </div>
}

const PointsScoreCard = (props: any) => {
    const param = useParams();
    const context:any = React.useContext(ProfileContext);
    const [profile, setProfile] = React.useState(context.profile);
    const [data, setData]: any = React.useState({});
    const [loading, setLoading]  = React.useState(true);

    const [modalForJobInFocus, setModalForJobInFocus] = React.useState(false);
    const [jobInFocus, setJobInFocus] = React.useState({});
    const [temporaryExcuseHolder, setTemporaryExcuseHolder] = React.useState<string>('');

    const getInitialData = async () => {
        const resp = await GetScoreCardActivity();
        if(resp){
            setData(resp);
            setTimeout(() => setLoading(false), 1000);
        }
    }

    const getEmployeeData = async () => {
        const resp = await GetScoreCardActivityForAnEmployee(param.id);
        setProfile(resp.data.profile);
        if(resp){
            console.log(resp);
            setData(resp);
            setTimeout(() => setLoading(false), 1000);
        } else {
            window.history.back();
        }
    }

    const setDataInFocus = async (data: any) => {
        setJobInFocus({'loading': true});
        setModalForJobInFocus(true);
    }

    const getJobReviewStatus = async () => {

    }

    const removeDatainFocus = async () => {
        setModalForJobInFocus(false);
        setTimeout(() => {
            setJobInFocus({});
            setTemporaryExcuseHolder('');
        }, 500)        
    }

    const saveExcuseToDatabase = async () => {
        if(temporaryExcuseHolder.length > 0){
        }
        removeDatainFocus();
    }

    React.useEffect(() => {
        if(param.id){
            getEmployeeData();
        } else {
            getInitialData();
        }
    }, []);

    if(loading){
        return <Loading />
    }

    return (
        <React.Fragment>
            {/*<React.Fragment>
                <Modal 
                cancelModal={removeDatainFocus}
                //@ts-ignore
                title={`${Object(jobInFocus).hasOwnProperty('loading') === true ? '' : `Job on ${jobInFocus?.job_date}`}`} 
                disabled={false} 
                showModal={modalForJobInFocus} 
                showModalFn={saveExcuseToDatabase}>
                    {
                        Object(jobInFocus).hasOwnProperty('loading') === true ? <React.Fragment>
                            
                        </React.Fragment> : <React.Fragment>
                            <h1 className='my-2'>Request For Review</h1>
                            <div className='text-left'>
                                If this you feel this job should not be counted towards your score, provide a comment and your manager will review.
                            </div>
                            <textarea 
                                className='border-2 my-4 p-1 w-full h-[150px]'
                                value={temporaryExcuseHolder}
                                onChange={(e) => setTemporaryExcuseHolder(e.target.value)}
                            />
                        </React.Fragment>
                    }
                </Modal>
            </React.Fragment>*/}
            <div className="w-full h-full overflow-y-scroll p-4 m:px-[10%] md:px-[30%]">
                <Row el={<StatCard icon={<TrophyIcon className={'text-brandGreen'} />} title={"T&B POINTS Earned"} value={data?.metrics?.tnbpoints} unit={''}/>}/>
                <RowLink disabled={false} link={param.id ? `/employees-list/${param.id}/activity` : '/profile/activity'} el={<StatCardSmall  icon={<ShieldCheckIcon className={`text-yellow-300`} width={20} height={20} />} title={'Productivity Points'} desc='Click to see details' value={data?.metrics?.productivityPoints}/>}/>
                {/*data?.metrics?.jobs?.map((item:any) => JobElement(item, setDataInFocus))*/}
                <div className='text-center font-bold text-gray-300'>
                    Conduct Points
                </div>
                {data?.metrics?.managerPoints?.map((item:any) => <RowLink disabled={true} el={
                    <StatCardSmallAccordian 
                        routeDisabled={true} 
                        icon={<CircleStackIcon className={`${item.type === 'Positive' ? 'text-green-300' : 'text-red-300'}`} 
                        width={20} height={20} />} 
                        title={item.reason} 
                        value={item.points}
                        isAccordion={true}
                        desc={
                            <>
                              {item.comment} <br /> <br />
                              {item.submission_date} @ {item.submitted_by_last}, {item.submitted_by_first}
                            </>
                          }
                    />}/>
                )}
            </div>
        </React.Fragment>
    )
}

export default PointsScoreCard;

/****
 * standalone list item: title and value
 * actionable list item: title, value, mod
 * 
 * 
 * 
 * 
 */