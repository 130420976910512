//@ts-nocheck
import React, {useEffect, useState} from 'react';
import { Routes, Route, Outlet, Link, useParams } from "react-router-dom";
import { SavingErrorToast, SavingToast, SavingSuccessToast } from '../../utils/notifications';
import { GetFormDefaultedForSpecific, GetFormDefaultedForGeneral,InsertFormResponse } from '../../utils/api';
import { ProfileContext } from '../../state/store';
import { ChevronRightIcon, CheckBadgeIcon } from '@heroicons/react/24/solid';
import Loading from '../../components/loading';
import Modal from '../../components/modal';




/**
 * text - input
 * longtext - textarea
 * select - dropdown
 * calendar - dropdown
 * 
 * 
 */



const REV_FORM = [
    {
        "type": "review_form",
        "title": "Revie Form",
        "version": 1,
        "depends": [
            {
                "fields": ["inputbox1", "inputbox2"],
                "function": "getName",
                "output_fields": 2
            },
            {   
                "field": ["selectionbox1", "selectionbox1"],
                "function": "getEmployees",
                "output_fields": 1
            }
        ],
        "fields": [
            {
                "id": "longtext1",
                "type": "longtext",
                "title": "Comments",
                "index": 3,
                "disabled": false,
                "defaultValue": null,
                "data": [],
                "canBeNull": true
            },
            {
                "id": "calendar1",
                "type": "calendar",
                "title": "Reivew Period",
                "index": 4,
                "disabled": true,
                "defaultValue": null,
                "data": [],
                "canBeNull": false
            },
            {
                "id": "selectionbox1",
                "type": "selectionbox",
                "title": "Reivew Period",
                "index": 5,
                "disabled": true,
                "defaultValue": null,
                "data": [],
                "canBeNull": false
            },
            {
                "id": "selectionbox2",
                "type": "selectionbox",
                "title": "Reivew Period",
                "index": 6,
                "disabled": true,
                "defaultValue": null,
                "data": [],
                "canBeNull": false
            },
            {
                "id": "inputbox1",
                "type": "inputbox",
                "title": "First Name",
                "index": 1,
                "disabled": true,
                "defaultValue": null,
                "data": [],
                "canBeNull": false
            },
            {
                "id": "inputbox2",
                "type": "inputbox",
                "title": "Last Name",
                "index": 2,
                "disabled": true,
                "defaultValue": null,
                "data": [],
                "canBeNull": false
            },
        ]
    }
]


const GeneralForm = (props: any) => {
    const param = useParams();
    


    const REVIEW_FORM_VAR = props.for;
    const context:any = React.useContext(ProfileContext);
    const [profile, setProfile] = React.useState(context.profile);
    const [data, setData]: any = React.useState({});
    const [loading, setLoading]  = React.useState(true);

    const [modalForJobInFocus, setModalForJobInFocus] = React.useState(false);
    const [jobInFocus, setJobInFocus] = React.useState({});
    const [temporaryExcuseHolder, setTemporaryExcuseHolder] = React.useState<string>('');

    const [content, setContent] = React.useState("");

    const getInitialData = async () => {
        const resp = await GetFormDefaultedForGeneral(REVIEW_FORM_VAR);
        if(resp){
            setContent(resp.data);
            //setTimeout(() => setLoading(false), 1000);
            setLoading(false)
        }
    }

    const getEmployeeData = async () => {
        const resp = await GetFormDefaultedForSpecific(param.id, REVIEW_FORM_VAR);
        console.log(resp);
        //setProfile(resp.data.profile);
        if(resp){
            setContent(resp.data);
            //setTimeout(() => setLoading(false), 1000);
            setLoading(false)
        } else {
            window.history.back();
        }
    }

    const setDataInFocus = async (data: any) => {
        setJobInFocus({'loading': true});
        setModalForJobInFocus(true);
    }

    const removeDatainFocus = async () => {
        setModalForJobInFocus(false);
        setTimeout(() => {
            setJobInFocus({});
            setTemporaryExcuseHolder('');
        }, 500)        
    }

    const saveExcuseToDatabase = async () => {
        if(temporaryExcuseHolder.length > 0){
        }
        removeDatainFocus();
    }

    React.useEffect(() => {
      if(param.id){
          getEmployeeData();
      } else {
          getInitialData();
      }

    }, []);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const objectToPush: any = {};

        // Enable all disabled fields temporarily
        const disabledFields = event.target.querySelectorAll(':disabled');
        disabledFields.forEach((field: any) => field.disabled = false);
    
        const formData: any = new FormData(event.target);
    
        // Log all form entries
        for (const [key, value] of formData.entries()) {
            objectToPush[key] = value;
        }
    
        console.log('The info has been submitted!');
        console.log(objectToPush);

        //I want to send objectToPush to my server. what is the function using fetch

    
        // Re-disable the fields that were initially disabled
        disabledFields.forEach((field: any) => field.disabled = true);
        disableSubmitButtonForSave();
        SavingToast();

        const response = await InsertFormResponse(objectToPush);
        
        if(response.data === false){
          SavingErrorToast();
          enableSubmitButtonForSave();
        } else {
          SavingSuccessToast();
          setSelectedValues({});
          //window.history.back();
        }
    }

    const disableSubmitButtonForSave = () => {
      document.getElementById(`submitbutton`).disabled = true;
      document.getElementById(`submitbutton`).classList.remove('bg-green-800');
      document.getElementById(`submitbutton`).classList.add('bg-gray-300');
    }

    const enableSubmitButtonForSave = () => {
      document.getElementById(`submitbutton`).disabled = false;
      document.getElementById(`submitbutton`).classList.remove('bg-gray-300');
      document.getElementById(`submitbutton`).classList.add('bg-green-800');
    }

    const formRef = React.useRef(null);
    const [selectedValues, setSelectedValues] = useState({});

    useEffect(() => {
        const formElement = formRef.current;
        
        window.SelectAll = () => {
          const checkboxes = document.querySelectorAll('.employee-checkbox');
          checkboxes.forEach(checkbox => {
            if (checkbox.offsetParent !== null) { // Check if the element is visible
              checkbox.checked = true;
            }
          });
            window.updateSelectedEmployees();
        }

        window.DeSelectAll = () => {
          const checkboxes = document.querySelectorAll('.employee-checkbox');
          checkboxes.forEach(checkbox => {
            if (checkbox.offsetParent !== null) { // Check if the element is visible
              checkbox.checked = false;
            }
          });
            window.updateSelectedEmployees();
        }
    
        // Function to toggle the drawer
        window.toggleDrawer = (fieldId, data) => {
          const drawer = document.getElementById('drawer');
          //console.log(drawer);
          if (drawer) {
            drawer.classList.add('open');
            drawer.classList.remove('closed');
            window.currentFieldId = fieldId;
            window.populateDrawerContent(data);
          }
        };
    
        // Function to close the drawer
        window.closeDrawer = () => {
          const drawer = document.getElementById('drawer');
          if (drawer) {
            drawer.classList.remove('open');
            drawer.classList.add('closed');
            currentFieldId = null;
          }
        };
    
        // Function to populate the drawer content
        window.populateDrawerContent = (data) => {
            console.log('populateDrawerContent');
            console.log(data);
          const categoryFilter = document.getElementById('categoryFilter');
          const employeeList = document.getElementById('employeeList');
    
          if (categoryFilter && employeeList) {
            // Populate team filter options
            //categoryFilter.innerHTML = '<option value="">All</option>' + data.map(item => `<option value="${item.category}">${item.category}</option>`).join('');
            const uniqueCategories = [...new Set(data.map(item => item.category))];
            categoryFilter.innerHTML = `<option value="">${data[0].filterLabel}</option>` +
            uniqueCategories.map(category => 
              '<option value="' + category + '">' + category + '</option>'
            ).join('');
            // Populate employee list
            console.log(selectedValues[window.currentFieldId]);
            employeeList.innerHTML = data.map(employee => `
                <div class="employee-item h-12 w-full border-2 flex items-center" data-value="${employee.value}" data-team="${employee.category}">
                <input type="checkbox" id="${employee.value}" class="employee-checkbox ml-2 h-full w-6" ${selectedValues[window.currentFieldId]?.includes(employee.value.toString()) ? 'checked' : ''}>
                    <label class="ml-2 font-bold" for="${employee.value}">${employee.label}</label>
                </div>
            `).join('');
    
            // Add event listeners to all employee checkboxes
            document.querySelectorAll('.employee-checkbox').forEach(checkbox => {
              checkbox.addEventListener('change', window.updateSelectedEmployees);
            });
    
            // Handle search and filter
            document.getElementById('searchEmployee')?.addEventListener('input', window.filterEmployees);
            categoryFilter.addEventListener('change', window.filterEmployees);
          }
        };
    
        // Function to update selected employees
        window.updateSelectedEmployees = () => {
          const selected = [];
          document.querySelectorAll('.employee-checkbox:checked').forEach(checkbox => {
            selected.push(checkbox.id);
          });
          console.log(window.currentFieldId);
          if (window.currentFieldId) {
            document.getElementById(`${window.currentFieldId}_input`).value = selected.join(',');

            document.getElementById(`openDrawer ${window.currentFieldId}_input_value`).innerText = selected.length > 0  ? `You Selected ${selected.length}` : 'Select One or More';
            document.getElementById(`closeDrawer`).innerText = selected.length > 0  ? `Save & Close (${selected.length})` : 'CLOSE';
            if(selected.length > 0){
              document.getElementById(`openDrawer ${window.currentFieldId}_input_value`).classList.add('bg-green-500'); // Add the desired class
            } else {
              document.getElementById(`openDrawer ${window.currentFieldId}_input_value`).classList.remove('bg-green-500'); // Add the desired class
            }
            

            setSelectedValues(prev => ({
                ...prev,
                [window.currentFieldId]: selected
              }));
              console.log(selectedValues);
          }
        };
    
        // Function to filter employees
        window.filterEmployees = () => {
          const searchValue = document.getElementById('searchEmployee').value.toLowerCase();
          const categoryFilterValue = document.getElementById('categoryFilter').value;
    
          document.querySelectorAll('.employee-item').forEach(item => {
            const employeeName = item.querySelector('label').innerText.toLowerCase();
            const employeeTeam = item.getAttribute('data-team');
    
            if (employeeName.includes(searchValue) && (categoryFilterValue === '' || employeeTeam === categoryFilterValue)) {
              item.style.display = 'flex';
            } else {
              item.style.display = 'none';
            }
          });
        };
        
        window.toggleFieldsBasedOnSelection = () => {
          try {
            const pointstype = document.getElementById('pointstype');
            const otherLongText = document.getElementById('pointstype_otherlongtext');
            const pointsSlider = document.getElementById('pointstype_points_given');

            const positiveParent = positiveSelections.parentElement;
            const negativeParent = negativeSelections.parentElement;
            const otherParent = otherLongText.parentElement;
            const pointsSliderParent = pointsSlider.parentElement;
            // Show or hide based on the selection
            if (pointstype.value === 'Positive') {
                positiveParent.style.display = 'block';
                negativeParent.style.display = 'none';
                negativeSelections.value = null;
                pointsSliderParent.style.display = 'block';
            } else if (pointstype.value === 'Negative') {
                positiveParent.style.display = 'none';
                negativeParent.style.display = 'block';
                positiveSelections.value = null;
                pointsSliderParent.style.display = 'block';
            } else {
                // Hide both if no valid selection is made
                positiveParent.style.display = 'none';
                negativeParent.style.display = 'none';
                pointsSliderParent.style.display = 'none';
            }
            if(negativeSelections.value === 'OTHER' || positiveSelections.value === 'OTHER'){
                otherParent.style.display = 'block';
            } else {
                otherLongText.value = null;
                otherParent.style.display = 'none';
            }
          } catch (e: any) {
            console.log(e);
          }
      };

      const pointstypeElement = document.getElementById('pointstype');
      const positiveSelections = document.getElementById('pointstype_positiveselections');
      const negativeSelections = document.getElementById('pointstype_negativeselections');
      
      if (pointstypeElement) {
            pointstypeElement.addEventListener('change', toggleFieldsBasedOnSelection);
            // Initial check when form loads
            toggleFieldsBasedOnSelection();
      }

      if (positiveSelections) {
          positiveSelections.addEventListener('change', toggleFieldsBasedOnSelection);
          // Initial check when form loads
          toggleFieldsBasedOnSelection();
      }

      if (negativeSelections) {
        negativeSelections.addEventListener('change', toggleFieldsBasedOnSelection);
        // Initial check when form loads
        toggleFieldsBasedOnSelection();
      }
    
        // Attach event listeners for click events
        //formElement?.addEventListener('click', handleClick);
        const drawer = document.getElementById('drawer');
        const openDrawerButton = document.getElementById('openDrawer');
        const closeDrawerButton = document.getElementById('closeDrawer');
        const searchEmployee = document.getElementById('searchEmployee');
        const categoryFilter = document.getElementById('categoryFilter');
        const employeeList = document.getElementById('employeeList');

        if (drawer) {
            if (closeDrawerButton) {
              closeDrawerButton.addEventListener('click', closeDrawer);
            }
        }
    
        // Cleanup event listeners
        return () => {
            document.getElementById('searchEmployee')?.removeEventListener('input', window.filterEmployees);
            document.getElementById('categoryFilter')?.removeEventListener('change', window.filterEmployees);
            if (pointstypeElement) {
              pointstypeElement.removeEventListener('change', toggleFieldsBasedOnSelection);
            }
            if (positiveSelections) {
              positiveSelections.removeEventListener('change', toggleFieldsBasedOnSelection);
              // Initial check when form loads
              toggleFieldsBasedOnSelection();
            }
    
            if (negativeSelections) {
              negativeSelections.removeEventListener('change', toggleFieldsBasedOnSelection);
              // Initial check when form loads
              toggleFieldsBasedOnSelection();
            }
          };
      }, [content, selectedValues]);

    if(loading){
        return <Loading />
    }

    return (
        <React.Fragment>
            <div className='h-full w-screen overflow-y-auto'>
                <main className='flex flex-col xs:flex-grow justify-start items-start sm:justify-center sm:items-center overflow-y-auto p-4 h-full'>
                    <form 
                        className='w-full max-w-xl' 
                        onSubmit={handleSubmit} 
                        dangerouslySetInnerHTML={{ __html: content }}
                        ref={formRef}
                    />
                </main>
            </div>
        </React.Fragment>
    )
}

export default GeneralForm;