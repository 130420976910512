import { Routes, Route, Link, Navigate } from "react-router-dom";
//import { ACCESS_ROLE } from "../utils/routeaccess";

const ProtectedRoute = (props: { user:any, roles: any, children:any }) => {
  //@ts-ignore
    if (!props.roles?.includes(props.user.role)) {
      return <Navigate to="/" replace />;
    }
  
    return props.children;
  };

export default ProtectedRoute;