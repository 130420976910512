import React from "react";
//import { VIEWS } from "./types";

//export const DisplayView:VIEWS = VIEWS.START;

export const fadeLoading = {
  loading: false,
  type: 3,
  desc: null,
  icon: null,
  showButton: false
}

export const dialogs = {
  signup: false,
  warning: false,
  networkSelection: false,
}

export const signup = false;

export const themes = {
  light: {
    dark: false,
    background: "var(--background)",
    colorTitle: "var(--text-title)",
    color: "var(--text)",
    iconForeground: "var(--background)",
    dialogTitle: "var(--background)",
    dialogIcon: "var(--text)",
    dialogIconForeground: "var(--background)",
    dialogContent: "var(--background-soft)",
    dialogFooter: "var(--background-dark)",
    dialogHeader: "var(--background-dark)",
    dialogText: "var(--text-dark)"
  },
  dark: {
    dark: true,
    background: "var(--background-dark)",
    colorTitle: "var(--text-title-dark)",
    color: "var(--text)",
    iconForeground: "var(--background)",
    dialogTitle: "var(--background-dark)",
    dialogIcon: "var(--text)",
    dialogIconForeground: "var(--background-dark)",
    dialogContent: "var(--background-soft)",
    dialogFooter: "var(--background)",
    dialogHeader: "var(--background)",
    dialogText: "var(--text-dark)"
  }
};

/*export const WeixLoadingContext = React.createContext({});
export const WeixThemeContext = React.createContext(themes.light);
export const WeixThemeControlsContext = React.createContext({});
export const WeixBlockchainContext = React.createContext({});*/
export const ProfileContext = React.createContext({});
export const ViewContext = React.createContext({});
export const AdminContext = React.createContext({});

export {}